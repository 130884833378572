import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Chips, Chip } from "../ui/Chips";
<h2>About Me</h2>;
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`I'm Daniel, a web developer based in western Canada. I do contract work for various clients through `}<a href="http://redwerks.org/">{`Redwerks`}</a>{` both solo work and as part of a team. I'm often contracted by clients to create whole new web applications and cross-platform mobile apps or when they need a React expert on their team.`}</p>
    <p>{`I specialize in React but am capable in many programing languages and flexible in the frameworks I use when more suitable.`}</p>
    <Chips mdxType="Chips">
  <Chip mdxType="Chip">TypeScript</Chip>
  <Chip mdxType="Chip">React</Chip>
  <Chip mdxType="Chip">React Native</Chip>
  <Chip mdxType="Chip">MUI</Chip>
  <Chip mdxType="Chip">Material Design</Chip>
  <Chip mdxType="Chip">SPA/SSR/SSG</Chip>
    </Chips>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      