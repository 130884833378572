import React from "react"
import styled from "@emotion/styled"
import { Card } from "./Cards"

export const SkillCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 32px;
  align-items: flex-start;
  margin-bottom: 32px;
`

/**
 * Skill card
 */
export const SkillCard = props => {
  const { title, children } = props

  return (
    <Card>
      <h3>{title}</h3>
      {children}
    </Card>
  )
}
