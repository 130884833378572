import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../ui/Container"
import { ProfileBlock } from "../components/ProfileBlock"
import About from "../profile/About"
import BlockSection from "../ui/BlockSection"
import Skills from "../profile/Skills"
import Portfolio from "../profile/Portfolio"
import { Button } from "../ui/Button"
import { BasicSection } from "../ui/BasicSection"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Container>
      <main>
        <ProfileBlock />
        <BlockSection>
          <About />
        </BlockSection>
        <Skills />
        <BasicSection>
          <h2>Portfolio</h2>
          <Portfolio variant="summary" />
          <Button to="/portfolio/">See More</Button>
        </BasicSection>
      </main>
    </Container>
  </Layout>
)

export default IndexPage
