import React from "react"
import { SkillCardContainer, SkillCard } from "../ui/SkillCard"
import { Chips, Chip } from "../ui/Chips"

/**
 * Skills section
 */
const Skills = (props) => {
  return (
    <SkillCardContainer>
      <SkillCard title="Programming Languages">
        <p>
          <small>
            My specialization is in TypeScript but I am open to learning new
            programming languages for projects and have prior experience using
            the following languages.
          </small>
        </p>
        <Chips>
          <Chip>PHP</Chip>
          <Chip>Ruby</Chip>
          <Chip>Python</Chip>
          <Chip>Java</Chip>
          <Chip>Bash</Chip>
          <Chip>Lua</Chip>
          <Chip>Objective-C</Chip>
          <Chip>C++/C#</Chip>
          <Chip>Go</Chip>
        </Chips>
      </SkillCard>
      <SkillCard title="Techniques">
        <Chips>
          <Chip>Responsive design</Chip>
          <Chip>Localization</Chip>
          <Chip>Accessibility*</Chip>
          <Chip>CSS Grid Layout</Chip>
          <Chip>Cross-platform mobile apps</Chip>
        </Chips>
      </SkillCard>
      <SkillCard title="React">
        <p>
          React is my preferred framework when it suits the project. I have
          developed client-side SPAs and isomorphic web apps with React DOM; and
          cross-platform mobile apps with React Native.
        </p>
        <Chips>
          <Chip>React</Chip>
          <Chip>React DOM</Chip>
          <Chip>React Native</Chip>
          <Chip>Tailwind CSS</Chip>
          <Chip>Tailwind UI</Chip>
          <Chip>MUI</Chip>
          <Chip>w/WebPack+Babel</Chip>
          <Chip>Vite</Chip>
          <Chip>Remix.run</Chip>
          <Chip>Next.js</Chip>
        </Chips>
      </SkillCard>
      <SkillCard title="Material Design">
        <p>
          I’ve worked on a number of projects that use Google's Material Design
          (GMD) as their base both on the web and as cross-platform mobile apps
          that either use GMD universally or follow platform design conventions.
          I have experience implementing GMD in projects as well as correcting
          designs to fit within GMD and follow GMD’s UX practices.
        </p>
      </SkillCard>
      <SkillCard title="Databases">
        <p>
          I’ve used various services and software when doing system
          administration for internal projects and some clients.
        </p>
        <Chips>
          <Chip>MongoDB</Chip>
          <Chip>MySQL</Chip>
          <Chip>PostgreSQL</Chip>
          <Chip>SQLite</Chip>
          <Chip>IndexedDB</Chip>
          <Chip>Firestore</Chip>
        </Chips>
      </SkillCard>
      <SkillCard title="Ops. Tools">
        <p>
          I’ve used various services and software when doing system
          administration for internal projects and some clients.
        </p>
        <Chips>
          <Chip>AWS</Chip>
          <Chip>Azure Cloud</Chip>
          <Chip>Google Cloud Engine</Chip>
          <Chip>Docker</Chip>
          <Chip>Kubernetes</Chip>
          <Chip>Terraform</Chip>
          <Chip>Puppet</Chip>
          <Chip>Nginx</Chip>
          <Chip>Apache</Chip>
          <Chip>Varnish</Chip>
          <Chip>H2O</Chip>
        </Chips>
      </SkillCard>
    </SkillCardContainer>
  )
}

export default Skills
