import React from "react"
import styled from "@emotion/styled"

const BlockSectionRoot = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "body photo";
  margin-bottom: 32px;
`
const BlockSectionBody = styled.div`
  grid-area: body;
`
const BlockSectionPhoto = styled.div`
  grid-area: photo;
`

/**
 * Page section with a small block of text and an associated image area
 */
const BlockSection = props => {
  const { children } = props

  return (
    <BlockSectionRoot>
      <BlockSectionBody>{children}</BlockSectionBody>
      <BlockSectionPhoto></BlockSectionPhoto>
    </BlockSectionRoot>
  )
}

export default BlockSection
